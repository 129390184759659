import React, { useEffect } from 'react';
import { COGNITO_CLAW_LOGOUT_ENDPOINT } from '../../helpers/constants';
import { clearLocalStorage } from "../../helpers/authUtils";
import { Spin } from 'antd';
import { AnalyticsService } from 'helpers/analyticsService';
import { EVENT_NAME } from 'helpers/analytics/eventEnums';

function ClawCognitoLogout() {
  useEffect(() => {
    clearLocalStorage();
    window.location.href = COGNITO_CLAW_LOGOUT_ENDPOINT;
    AnalyticsService.reportEventV2(EVENT_NAME.LOG_OUT, undefined);
  }, []);

  return (
    <Spin />
  );
}

export default ClawCognitoLogout;