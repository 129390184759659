import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./AuthLayout.scss";
import { Layout } from "antd";
import PageFooter from "../../components/Footer";
import PageSidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setWindowType } from "../../store/app/action";
import { useWindowSize } from "../../hooks/useWindowSize";
import { windowSelector } from "../../store/app/selectors";
import { agencyListingsTotalSelector, listingLoadingSelector, listingsListSelector } from "../../store/listings/selectors";
import { landlordCreateSuccessSelector, landlordLoadingSelector, landlordsListSelector } from "../../store/landlords/selectors";
import { followUpsLoadingSelector } from "../../store/followUps/selectors";
import { teamMembersLoadingSelector } from "../../store/teams/selectors";
import { agentsActivitiesLoadingSelector } from "../../store/activities/selectors";
import { authLoadingSelector, userSelector } from "../../store/user/selectors";
import { leadDataSelector, leadLoadingSelector } from "../../store/leads/selectors";
import WelcomeModal from "./welcomeModal/WelcomeModal";
import { useLocation, useParams } from "react-router-dom";
import { getNoteComponent } from "components/NotificationsLine/helpers";
import { TourProvider } from "@reactour/tour";
import { tabsSteps } from "components/Shared/BaseTourModal/dataWithJsx";
import { AnalyticsService } from "../../helpers/analyticsService";
import routes from "../../routes/routesCode";
import AuthHeaderMobileOnboard from "../../components/Header/AuthHeaderMobileOnboard/AuthHeaderMobileOnboard";
import { clearListingsFromAlgolia } from "../../store/listings/action";
import { dashboardLoadingSelector } from "store/dashboard/selectors";
import Message from "components/Shared/Message";
import { useDemoContext } from "libs/contextLib";
// import FloatingActionButton from "../../components/Shared/floating-action-button/FloatingActionButton";
import Cookies from "js-cookie";
// import ExpirationMessage from "../../components/Shared/Message/ExpirationMessage";
import { openNotification, setUserPropertiesForAnalytics } from "../../helpers/globalFunctions";
import { updatedSubscriptionSelector } from "../../store/payment/selectors";
import { getLoggedInUser } from "../../store/user/action";
import { setUpdatedSubscriptionToBlank } from "../../store/payment/action";
import PageHeader from "../../components/Header/auth-header";
import { IObjectKeys } from "helpers/globalTypes";
import HubspotApi from "../../api/Hubspot";
import { HUBSPOT_FIELDS, SOURCE_ENUM } from "helpers/constants";
import { agentAllSRSelector } from "store/agent/selectors";
import { isRootRoute } from "helpers/clientRoutes";
import { EVENT_NAME } from "helpers/analytics/eventEnums";
import Suspense from "hocs/suspense";
// import { getStartOfUnitFormatted } from "../../helpers/dateHelper";

const bodyScrollLock = require("body-scroll-lock");

const { Content, Footer } = Layout;
export interface AuthLayoutProps {
  children: React.ReactNode;
  user?: any;
}

const AuthLayout: React.FC<AuthLayoutProps> = (props: AuthLayoutProps) => {
  const { user } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const updatedSubscription = useSelector(updatedSubscriptionSelector);
  const leadLoading = useSelector(leadLoadingSelector);
  const authLoading = useSelector(authLoadingSelector);
  const { allLoading: tenantLoading } = useSelector(agentAllSRSelector);
  const { type, collapsed } = useSelector(windowSelector);
  const listingLoading = useSelector(listingLoadingSelector);
  const landlordLoading = useSelector(landlordLoadingSelector);
  const followUpsLoading = useSelector(followUpsLoadingSelector);
  const teamMembersLoading = useSelector(teamMembersLoadingSelector);
  const dashboardLoading = useSelector(dashboardLoadingSelector);
  const agentsActivitiesLoading = useSelector(agentsActivitiesLoadingSelector);
  const landlordsList = useSelector(landlordsListSelector);
  const agencyListingsTotal = useSelector(agencyListingsTotalSelector);
  const createLandlordSuccess = useSelector(landlordCreateSuccessSelector);
  const leadsData = useSelector(leadDataSelector);
  const [fabBtnClicked, setFabBtnClicked] = useState(true);
  const isSuccessPath = location.pathname.includes("success") || location.pathname.includes("reset-password");
  const [isReachedBottom, setIsReachedBottom] = useState(false);
  const [scrollingClientHeight, setScrollingClientHeight] = useState(0);
  const [bottomClientHeight, setBottomClientHeight] = useState(0);
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const onScroll = (scrollRef: any) => {
    if (isFilterOpened) {
      const epsilon = 0.5;
      const condition1 =
        Math.abs(scrollRef.target.scrollHeight - 170 - scrollRef.target.scrollTop + 170 - scrollRef.target.clientHeight - 170) < epsilon;
      const condition2 =
        Math.floor(scrollRef.target.scrollHeight - 170 - scrollRef.target.scrollTop + 170 - scrollRef.target.clientHeight - 170) < epsilon;
      if (condition1 || condition2) {
        setBottomClientHeight(scrollRef.target.scrollHeight - 170 - scrollRef.target.scrollTop + 170);
        setIsReachedBottom(true);
      }
    } else {
      setIsReachedBottom(false);
      setBottomClientHeight(0);
    }
  };

  const { lead_id, landlordId, lId }: any = useParams();
  const [isFooterCollapsed, setIsFooterCollapsed] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [scrollEnabled, setScrollEnabled] = useState("");
  const [signUpFirstTime, setSignUpFirstTime] = useState(false);
  const [shouldReportSignUpSuccess, setShouldReportSignUpSuccess] = useState(false);
  const [isSidebarVisible, setisSidebarVisible] = useState(true);
  const [isLeadsPageMobile, setIsLeadsPageMobile] = useState(false);
  const [notification, setNotification] = useState("");
  const listingsList = useSelector(listingsListSelector);
  const { isDemo } = useDemoContext();
  const { width } = useWindowSize();
  const isEmptyState = location.search.includes("qualifyLead=true") || location.search.includes("fab=true");

  const toggleSidebar = useCallback(() => {
    const window = {
      type,
      collapsed: !collapsed,
    };
    localStorage.setItem("sidebarParams", JSON.stringify(window));
    dispatch(setWindowType(window));
  }, [type, collapsed, dispatch]);

  // const leftDaysGenerator = useCallback((date: any) => {
  //   const given = moment(date).startOf("day");
  //   const current = getStartOfUnitFormatted(new Date(), "day");
  //   if (given.diff(current, "days") > 0) {
  //     return `${given.diff(current, "days")} days`;
  //   } else {
  //     return "today";
  //   }
  // }, []);

  const routesData = useMemo(() => {
    return [
      // eslint-disable-next-line no-restricted-globals
      [routes.listing.path, location.pathname.includes(lId)],
      [routes.landlords.path, landlordId ? true : !!landlordsList.length],
      [routes.manageListing.path, true],
      [routes.listings.path, !(!agencyListingsTotal && !location.pathname.includes(lId)) || listingsList?.length !== 0],
      [routes.followUps.path, true],
      [routes.settings.path, true],
      [routes.requirementSuccess.path, true],
      ["/teamDashboard", true],
      [routes.home.path, true],
    ];
  }, [location, agencyListingsTotal, leadsData, landlordsList, user, lead_id, landlordId, lId, listingsList]);

  const updateHubspotContact = async (data: IObjectKeys) => {
    try {
      await HubspotApi.updateHubspotContact({
        user: data.user,
        event: data.event,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    const unloadCallback = (event: any) => {
      localStorage.removeItem("RBsession");
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    dispatch(clearListingsFromAlgolia());
  }, [lId]);

  useEffect(() => {
    const targetRoute = routesData.find((d: any) => location.pathname.includes(d[0]));
    const leadShare = location.search.includes("isRenterShared");
    if (
      (targetRoute && (targetRoute[1] || location.search) && !leadShare && !location.search.includes("premium_success")) ||
      location.pathname === routes.agentAllScreening.path
    ) {
      setIsHeaderVisible(true);
    } else {
      setIsHeaderVisible(false);
    }
    if (
      location.pathname.includes("requirements/success") ||
      location.pathname.includes("/reset-password") ||
      location.pathname.includes("/shareable_terms_and_conditions")
    ) {
      setisSidebarVisible(false);
      setIsHeaderVisible(false);
    } else {
      setisSidebarVisible(true);
    }
  }, [location, routesData]);

  useEffect(() => {
    if (Object.keys(user).length && !user?.phone_verified && !location.pathname.includes("settings")) setNotification("usersPhoneVerified");
    else setNotification("");
  }, [user, location.pathname]);

  const isFirstTime = !user.last_visit && !localStorage.getItem("user-last-visit") && Object.keys(user).length && user.id;

  useEffect(() => {
    if (isFirstTime) {
      setSignUpFirstTime(true);
      setShouldReportSignUpSuccess(true);
    } else {
      setSignUpFirstTime(false);
    }
    user.adminUser ? localStorage.setItem("AdminLogin", "true") : localStorage.setItem("AdminLogin", "false");

    if (user && !localStorage.getItem("RBsession") && !user.adminUser) {
      localStorage.setItem("RBsession", "true");
      setUserPropertiesForAnalytics(user.role, user.id, user.agency);

      const userProperties = {
        user_id: user._id,
        agency_id: user.agency?._id,
        user_name: user.name,
        user_email: user.email,
        user_phone: user?.phone,
        office_name: user?.office_name,
        user_source: user.source?.name ?? SOURCE_ENUM.RENTBASE,
        phone_verified: user?.phone_verified,
        email_verified: user?.account_verified,
      };
      AnalyticsService.reportEventV2(EVENT_NAME.RB_SESSION_START, userProperties);
    }
  }, [isFirstTime, user]);

  useEffect(() => {
    if (shouldReportSignUpSuccess) {
      const isAllSet = setUserPropertiesForAnalytics(user.role, user.id, user.agency);

      if (isAllSet) {
        AnalyticsService.reportSignUpProcess({ action: "signup_success" });
        setShouldReportSignUpSuccess(false);
      }
      if (user.email && user.name) updateHubspotContact({ user: user, event: HUBSPOT_FIELDS.COMPLETED_SIGNUP_PROCESS });
    }
  }, [shouldReportSignUpSuccess, user.role, user.id, user.agency, user.email, user.name]);

  useEffect(() => {
    //@ts-ignore
    if ((width <= 1152 && collapsed === false) || !fabBtnClicked) {
      setScrollEnabled("disabled-scroll");
    } else setScrollEnabled("enable-scroll");
  }, [width, collapsed, fabBtnClicked]);

  const collapseFooter = () => {
    setIsFooterCollapsed((prevState) => !prevState);
  };

  const closeWelcomeModal = () => {
    setSignUpFirstTime(false);
    localStorage.setItem("tabs walkthrough", "first");
    localStorage.setItem("user-last-visit", JSON.stringify(true));
  };

  useEffect(() => {
    if (window.innerHeight - bottomClientHeight < 0) {
      setScrollingClientHeight(window.innerHeight - bottomClientHeight);
    } else {
      setScrollingClientHeight(window.innerHeight);
    }
  }, [bottomClientHeight]);

  useEffect(() => {
    if (Object.keys(updatedSubscription).length) {
      dispatch(getLoggedInUser());
      dispatch(setUpdatedSubscriptionToBlank());
      if (!updatedSubscription.url) {
        openNotification("success", "Upgraded successfully");
      }
    }
  }, [updatedSubscription]);

  return (
    // @ts-ignore
    <TourProvider
      data-test-id="page-auth-layout"
      steps={tabsSteps}
      onClickMask={({ setIsOpen }) => {
        setIsOpen(false);
      }}
      scrollSmooth
      position="right"
      padding={{ mask: 0, popover: [5, 0] }}
      afterOpen={(target) => bodyScrollLock.disableBodyScroll(target)}
      beforeClose={(target) => {
        bodyScrollLock.enableBodyScroll(target);
        localStorage.setItem("tabs walkthrough", "done");
      }}
      styles={{
        popover: (base) => ({
          ...base,
          width: "150%",
          borderRadius: 6,
        }),
        maskArea: (base: any) => ({ ...base, rx: 6 }),
        badge: (base) => ({
          ...base,
          fontFamily: "Open-sans",
          background: "#5806B9",
          paddingLeft: "0.8125em",
          paddingRight: "0.8125em",
          fontSize: "1em",
          borderRadius: "1.625em",
          boxShadow: "0 0.25em 0.5em rgba(0, 0, 0, 0.3)",
        }),
        dot: (base, args) => ({
          ...base,
          background: args?.current && "#5806B9",
        }),
      }}
      disableInteraction
    >
      {notification && !user.phone_verified && !Cookies.get("phone_message") ? getNoteComponent({ notification }) : ""}
      <div onScroll={onScroll} style={{ height: "100%" }}>
        <Layout
          data-test-id="page-auth-layout"
          className={`auth-layout-wrapper page-content-layout ${scrollEnabled} ${!isHeaderVisible && !location.pathname.includes("/manageListing") && "auth-layout-wrapper-onboarding"
            }`}
          hasSider
        >
          {isSidebarVisible && (
            <PageSidebar
              menuItems={routes}
              toggleSidebar={toggleSidebar}
              setVisibility={fabBtnClicked}
            // isLoading={idebarIsLoading}
            />
          )}
          <WelcomeModal visible={signUpFirstTime} onCancel={closeWelcomeModal} />
          {type !== "desktop" && !collapsed && <div onClick={toggleSidebar} className="backdrop-layout" />}
          <Layout
            className={`layout auth-layout-body bg-white 
              ${leadLoading ||
                authLoading ||
                listingLoading ||
                (landlordLoading && (!location.pathname.includes("manageListing") || !location.pathname.includes("listings"))) ||
                followUpsLoading ||
                tenantLoading ||
                teamMembersLoading ||
                agentsActivitiesLoading ||
                dashboardLoading
                ? "auth-layout-body-loading"
                : null
              }
              `}
          >
            <div>
              {isHeaderVisible && !isDemo && !isEmptyState ? <PageHeader setIsFilterOpened={setIsFilterOpened} /> : null}
              {(!isHeaderVisible || isDemo || isEmptyState) && type !== "desktop" && isSidebarVisible ? <AuthHeaderMobileOnboard /> : null}
              <Content
                className={`site-layout-background site-layout-main main-page-container ${isLeadsPageMobile ? "leads-page-layout-background" : null
                  }`}
              >
                {props.children}
              </Content>
            </div>
            <Footer className={`site-layout-footer ${isFooterCollapsed ? "collapsed" : ""}`}>
              <PageFooter isFooterCollapsed={isFooterCollapsed} collapseFooter={collapseFooter} />
            </Footer>
          </Layout>
        </Layout>
        {createLandlordSuccess && <Message text="👍  Landlord created Successfully" messageClassName="alert-new" type="noIcon" />}
        {/* <FloatingActionButton
          setFabBtn={setFabBtnClicked}
          isReachedBottom={isReachedBottom}
          scrollingClientHeight={scrollingClientHeight}
        /> */}
      </div>
    </TourProvider>
  );
};
export default AuthLayout;
