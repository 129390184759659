import { all, fork, put, takeLatest, call, delay } from "redux-saga/effects";
import {
  SIGN_OUT,
  SIGN_IN,
  GET_LOGGED_IN_USER,
  FORCE_RESET_PASSWORD,
  RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD,
  UPDATE_USER_SETTINGS,
  GET_PHONE_VERIFICATION_CODE,
  VERIFY_PHONE_NUMBER,
  GET_LEAD_PHONE_VERIFICATION_CODE,
  VERIFY_LEAD_PHONE_NUMBER,
  SIGN_UP_AGENT,
  EXPORT_USER_DATA,
  VERIFY_BY_EMAIL_LINK,
  GET_REFERRAL_AGENT,
  UPDATE_USER_PROFILE,
  UPDATE_USER_AFTER_SWITCH,
} from "./types";
import AuthApi from "api/Auth";
import { push } from "connected-react-router";
import routes from "../../routes/routesCode";
import { COGNITO_LOGOUT_ENDPOINT } from "../../helpers/constants";
import { clearLocalStorage, setAccessToken, setRefreshToken } from "../../helpers/authUtils";
import {
  getLoggedInUserFail,
  getLoggedInUserSuccess,
  forceResetPasswordFail,
  forceResetPasswordSuccess,
  signInFail,
  signInSuccess,
  signOutSuccess,
  signOut,
  resetPasswordSuccess,
  resetPasswordFail,
  confirmResetPasswordSuccess,
  confirmResetPasswordFail,
  updateUserSettingsSuccess,
  updateUserSettingsFail,
  getPhoneVerificationCodeSuccess,
  getPhoneVerificationCodeFail,
  verifyPhoneNumberSuccess,
  verifyPhoneNumberFail,
  getLeadPhoneVerificationCodeSuccess,
  getLeadPhoneVerificationCodeFail,
  verifyLeadPhoneNumberSuccess,
  verifyLeadPhoneNumberFail,
  signUpAgentFail,
  signUpAgentSuccess,
  isLeadPageShared,
  exportAgentDataSuccess,
  exportAgentDataFail,
  setVerificationPhoneSuccess,
  verifyByEmailLinkSuccess,
  verifyByEmailLinkFail,
  getReferralAgentInfoSuccess,
  updateUserProfileFail,
  updateUserProfileSuccess,
  updateUserAfterSwitchSuccess,
  updateUserAfterSwitchFail,
} from "./action";
import { beautifiedKeyStyling, getSrRenterIdFromQuery, openNotification } from "../../helpers/globalFunctions";
import { AnalyticsService } from "helpers/analyticsService";
import LeadsApi from "../../api/Leads";
import { clearLeadLink } from "../leads/action";
import ExportApi from "../../api/Export";
import UsersApi from "../../api/Users";
import { urlQueryToObject } from "components/Shared/filters/helpers/listingsCreateGetSearchQuery";
import { EVENT_NAME } from "helpers/analytics/eventEnums";

function* signInSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.logIn, payload.data);
    const { access_token, refresh_token, ...userData } = response;
    yield setAccessToken(access_token);
    yield setRefreshToken(refresh_token);
    yield put(signInSuccess(userData));
    if (
      payload.search.includes("listings") ||
      payload.search.includes("landlords") ||
      payload.search.includes("leads") ||
      payload.search.includes("settings") ||
      payload.search.includes("teamDashboard") ||
      payload.search.includes("followUps") ||
      payload.search.includes("teamsMembers") ||
      payload.search.includes("tenant-screenings")
    ) {
      yield put(push(`${payload.search?.substring(1)}`));
    } else if (getSrRenterIdFromQuery(payload?.search)) {
      yield put(push(`/${payload.search}`));
    } else {
      yield put(push("/"));
    }
    AnalyticsService.reportEvent("sign_in_response", {
      category: "App",
      response: "success",
    });
    sessionStorage.removeItem("force_logout_last_page");
  } catch (e) {
    if (e) {
      openNotification("error", "Invalid Credentials.");
      AnalyticsService.reportEvent("sign_in_response", {
        category: "App",
        response: "error",
        errorMessage: e.message,
      });
    }
    yield put(signInFail(e));
  }
}

function* signOutSaga({ payload }: any) {
  yield clearLocalStorage();
  yield put(signOutSuccess());
  yield put(clearLeadLink());
  window.location.href = COGNITO_LOGOUT_ENDPOINT;
  AnalyticsService.reportEventV2(EVENT_NAME.LOG_OUT, undefined);

  // NOTE: The below is not longer in use because of SAML call to MLS above
  // if (!window.location.href.includes("shared=true")) {
  //   if (window.location.href.includes("listings") && window.location.href.includes("isShared=true")) {
  //     yield put(
  //       push({
  //         pathname: `/public/${redirectUrl}?isShared=true`,
  //       })
  //     );
  //   } else if (window.location.href.includes("followUps")) {
  //     if (window.location.href.includes("view=completed")) {
  //       yield put(
  //         push({
  //           pathname: routes.signIn.path,
  //           search: `?${redirectUrl}?view=completed`,
  //         })
  //       );
  //     } else if (window.location.href.includes("id=")) {
  //       yield put(
  //         push({
  //           pathname: routes.signIn.path,
  //           search: `?${redirectUrl}`,
  //         })
  //       );
  //     } else {
  //       yield put(
  //         push({
  //           pathname: routes.signIn.path,
  //           search: `?${redirectUrl}`,
  //         })
  //       );
  //     }
  //   } else {
  //     yield put(
  //       push({
  //         pathname: routes.signIn.path,
  //         search: `?${redirectUrl}`,
  //       })
  //     );
  //   }
  // } else {
  //   yield put(
  //     push({
  //       pathname: routes.signUp.path,
  //       search: `${redirectUrl}?shared=true`,
  //     })
  //   );
  //   yield put(isLeadPageShared());
  // }
}

function* signUpAgentSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.signUp, payload.data);
    yield put(signUpAgentSuccess(response));
    AnalyticsService.reportSignUpProcess({
      action: "signup_submit_response",
      response: "success",
    });
  } catch (e) {
    if (e) {
      openNotification("error", e.email || e.phone || e.name || e.message);
      AnalyticsService.reportSignUpProcess({
        action: "signup_submit_response",
        response: "error",
      });
    }
    yield put(signUpAgentFail(e));
  }
}

function* forceResetPasswordSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.forceResetPassword, payload);
    const { access_token, refresh_token, ...userData } = response;
    if (!access_token || !refresh_token) {
      return yield put(signOut({ redirectUrl: "" }));
    }
    yield setAccessToken(access_token);
    yield setRefreshToken(refresh_token);
    yield put(forceResetPasswordSuccess(userData));
    // if(localStorage.getItem("new-member-from-sign-up") === "true"){
    //
    // }else {
    //   yield put(push('/'));
    // }
  } catch (e) {
    const errorMessage = Object.entries(e)[0];
    // eslint-disable-next-line no-restricted-globals
    if (errorMessage?.length) {
      openNotification("error", `${beautifiedKeyStyling(errorMessage[0])}:: ${errorMessage[1]}`);
    }
    yield put(forceResetPasswordFail(e));
  }
}

function* getLoggedInUserSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.getLoggedInUser);
    yield delay(200);
    yield put(getLoggedInUserSuccess(response));
    localStorage.setItem("user_role", response.role);
  } catch (e) {
    clearLocalStorage();
    put(
      push({
        pathname: routes.signUp.path,
        search: payload,
      })
    );
    yield put(getLoggedInUserFail(e));
  }
}

function* exportAgentDataSaga() {
  try {
    yield call(ExportApi.exportAgentData);
    yield put(exportAgentDataSuccess());
  } catch (e) {
    yield put(exportAgentDataFail(e));
  }
}

function* updateUserSettingsSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.updateUser, {
      params: { _id: payload._id },
      data: { ...payload },
    });
    if (response) {
      if (!window.location.pathname.includes("forceResetPassword")) {
        openNotification("success", "User Settings has been successfully updated.");
      }
      yield put(updateUserSettingsSuccess(response));

      AnalyticsService.reportEvent("form_submit", {
        category: "App",
        label: "update user settings | success",
      });
    }
  } catch (e) {
    if (e.message) {
      openNotification("error", e.message);
    }
    yield put(updateUserSettingsFail(e));

    AnalyticsService.reportEvent("form_submit", {
      category: "App",
      label: "update user settings | error",
      errorMessage: e.message,
    });
  }
}

function* resetPasswordSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.resetPassword, payload);
    yield put(resetPasswordSuccess(response));
    AnalyticsService.reportEvent("form_submit", {
      category: "App",
      label: "reset password - get code by mail | success",
    });
  } catch (e) {
    openNotification("error", `${e.message}`);
    yield put(resetPasswordFail(e));
    AnalyticsService.reportEvent("form_submit", {
      category: "App",
      label: "reset password - get code by mail | error",
      errorMessage: e.message,
    });
  }
}

function* confirmResetPasswordSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.confirmResetPassword, payload);
    openNotification("success", "Your password has been reset successfully");
    yield put(confirmResetPasswordSuccess(response));
    AnalyticsService.reportEvent("form_submit", {
      category: "App",
      label: "reset password | success",
    });
  } catch (e) {
    openNotification("error", `${e.message}`);
    yield put(confirmResetPasswordFail(e));
    AnalyticsService.reportEvent("form_submit", {
      category: "App",
      label: "reset password | error",
      errorMessage: e.message,
    });
  }
}

function* getPhoneVerificationCodeSaga() {
  try {
    const response = yield call(AuthApi.getVerificationCode);
    if (response.success) {
      if (!window.location.pathname.includes("forceResetPassword")) {
        const message = response.message ?? "Your verification code sent successfully!";
        openNotification("success", message);
      }
      yield put(getPhoneVerificationCodeSuccess(response));
      yield put(setVerificationPhoneSuccess(true));
    }
  } catch (e) {
    openNotification("error", `${e.message}`);
    yield put(getPhoneVerificationCodeFail(e));
    yield put(setVerificationPhoneSuccess(false));
  }
}
function* getLeadPhoneVerificationCodeSaga(data: any) {
  try {
    const response = yield call(LeadsApi.getVerificationCode, data);
    if (response.success) {
      const message = response.message ?? "Your verification code sent successfully!";
      openNotification("success", message);
      yield put(getLeadPhoneVerificationCodeSuccess(response));
    } else if (response.response.error) {
      throw new Error(response.message);
    }
  } catch (e) {
    openNotification("error", `${e.The || e}`);
    yield put(getLeadPhoneVerificationCodeFail(e));
  }
}
function* verifyPhoneNumberSaga({ payload }: any) {
  try {
    const response = yield call(AuthApi.verifyPhoneNumber, payload);
    if (response.phone_verified) {
      if (!window.location.pathname.includes("forceResetPassword")) {
        openNotification("success", "Your phone number has been verified successfully!");
      }
      yield put(verifyPhoneNumberSuccess(response));
    }
  } catch (e) {
    openNotification("error", `${e.message}`);
    yield put(verifyPhoneNumberFail(e));
  }
}
function* verifyLeadPhoneNumberSaga({ payload }: any) {
  try {
    const response = yield call(LeadsApi.verifyPhoneNumber, payload);
    if (response.phone_verified) {
      if (!window.location.pathname.includes("forceResetPassword")) {
        openNotification("success", "Your phone number has been verified successfully!");
      }
      yield put(verifyLeadPhoneNumberSuccess(response));
    } else if (response.response.error) {
      throw new Error(response.message);
    }
  } catch (e) {
    openNotification("error", `${e.message}`);
    yield put(verifyLeadPhoneNumberFail(e));
  }
}

function* verifyByEmailLinkSaga({ payload }: any) {
  try {
    const response = yield call(UsersApi.verifyUserFromEmail, payload);
    yield put(verifyByEmailLinkSuccess(response));
  } catch (e) {
    yield put(verifyByEmailLinkFail(e));
  }
}

function* getReferralAgentInfoSaga({ payload }: any) {
  try {
    const response = yield call(UsersApi.getReferralAgentInfo, payload);
    yield put(getReferralAgentInfoSuccess(response));
  } catch (e) {
    yield put(getLoggedInUserFail(e));
  }
}

function* updateUserProfileSaga({ payload }: any) {
  try {
    const response = yield call(UsersApi.updateUserProfile, payload);
    yield put(updateUserProfileSuccess(response));
  } catch (e) {
    yield put(updateUserProfileFail(e));
  }
}

function* updateUserAfterSwitchSaga({ payload }: any) {
  console.log("in the user updateUserSaga");
  try {
    const response = yield call(UsersApi.updateUserAfterSwitch, payload);
    if (response) {
      openNotification("success", "You are now part of your new team.");
    }
    console.log("===> response: ", response);
    yield put(updateUserAfterSwitchSuccess(response));
  } catch (e) {
    console.log("e: ", e);
    if (e.message) {
      openNotification("error", e.message);
    }
    yield put(updateUserAfterSwitchFail(e));
  }
}

function* watchVerifyByEmailLinkSaga() {
  yield takeLatest(VERIFY_BY_EMAIL_LINK, verifyByEmailLinkSaga);
}
function* watchSignIn() {
  yield takeLatest(SIGN_IN, signInSaga);
}
function* watchSignUpAgent() {
  yield takeLatest(SIGN_UP_AGENT, signUpAgentSaga);
}
function* watchExportAgentData() {
  yield takeLatest(EXPORT_USER_DATA, exportAgentDataSaga);
}

function* watchForceResetPassword() {
  yield takeLatest(FORCE_RESET_PASSWORD, forceResetPasswordSaga);
}

function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}

function* watchConfirmResetPassword() {
  yield takeLatest(CONFIRM_RESET_PASSWORD, confirmResetPasswordSaga);
}

function* watchGetLoggedInUser() {
  yield takeLatest(GET_LOGGED_IN_USER, getLoggedInUserSaga);
}

function* watchUpdateUserSettings() {
  yield takeLatest(UPDATE_USER_SETTINGS, updateUserSettingsSaga);
}

function* watchGetPhoneVerificationCode() {
  yield takeLatest(GET_PHONE_VERIFICATION_CODE, getPhoneVerificationCodeSaga);
}

function* watchGetLeadPhoneVerificationCode() {
  yield takeLatest(GET_LEAD_PHONE_VERIFICATION_CODE, getLeadPhoneVerificationCodeSaga);
}

function* watchVerifyLeadPhoneNumber() {
  yield takeLatest(VERIFY_LEAD_PHONE_NUMBER, verifyLeadPhoneNumberSaga);
}

function* watchVerifyPhoneNumber() {
  yield takeLatest(VERIFY_PHONE_NUMBER, verifyPhoneNumberSaga);
}

function* watchSignOut() {
  yield takeLatest(SIGN_OUT, signOutSaga);
}

function* watchGetReferralAgent() {
  yield takeLatest(GET_REFERRAL_AGENT, getReferralAgentInfoSaga);
}

function* watchUpdateUserProfile() {
  yield takeLatest(UPDATE_USER_PROFILE, updateUserProfileSaga);
}

function* watchUpdateUserAfterSwitch() {
  yield takeLatest(UPDATE_USER_AFTER_SWITCH, updateUserAfterSwitchSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchSignIn),
    fork(watchForceResetPassword),
    fork(watchResetPassword),
    fork(watchConfirmResetPassword),
    fork(watchGetLoggedInUser),
    fork(watchUpdateUserSettings),
    fork(watchGetPhoneVerificationCode),
    fork(watchGetLeadPhoneVerificationCode),
    fork(watchVerifyLeadPhoneNumber),
    fork(watchVerifyPhoneNumber),
    fork(watchSignOut),
    fork(watchSignUpAgent),
    fork(watchExportAgentData),
    fork(watchVerifyByEmailLinkSaga),
    fork(watchGetReferralAgent),
    fork(watchUpdateUserProfile),
    fork(watchUpdateUserAfterSwitch),
  ]);
}
