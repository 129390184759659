import React, { useCallback } from "react";
import { Menu, Popover } from "antd";
import { push } from "connected-react-router";
import { signOut } from "../../../../store/user/action";
import { useDispatch, useSelector } from "react-redux";
import { windowSelector } from "../../../../store/app/selectors";
import { userSelector } from "store/user/selectors";
import { useLocation } from "react-router-dom";
import "./BottomMenu.scss";
import SettingsIcon from "../../../icons/SettingsIcon";
import { useDemoContext } from "libs/contextLib";
import LogoutIcon from "../../../icons/LogoutIcon";
import { PERSONAS } from "routes/routesCode";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import { AnalyticsService } from "helpers/analyticsService";
import { EVENT_NAME } from "helpers/analytics/eventEnums";

interface IBottomMenuInterface {
  isFromHeader?: boolean;
  toggleSidebar: Function;
}

const BottomMenus = (props: IBottomMenuInterface) => {
  const dispatch = useDispatch();
  const { type, collapsed } = useSelector(windowSelector);
  const location = useLocation();
  const { isDemo, demoAction } = useDemoContext();
  const user = useSelector(userSelector);

  const detectIconHandler = useCallback((navItem: string) => {
    let icon = <LogoutIcon />;
    switch (navItem) {
      case 'settings':
        icon = <SettingsIcon />;
        break;
      default:
        break;
    }
    if (type === "desktop") {
      if (collapsed) {
        return (
          <span role="img" className="ant-menu-item-icon">
            {icon}
          </span>
        );
      } else {
        return (
          <span role="img" className="ant-menu-item-icon">
            {icon}
          </span>
        );
      }
    }
  }, [collapsed, type]);

  return (
    <div
      className={`bottom-menus-wrapper ${collapsed && "bottom-menus-wrapper-collapsed"
        }`}
    >
      <Menu className={"bottom-menus"}>
        <Menu.Item
          title={null}
          className={`bottom-menus--accountSettings ${location.pathname.includes("settings") &&
            "bottom-menus--accountSettingsActive"
            }`}
          icon={detectIconHandler('settings')}
          key="settings"
          onClick={() => {
            isDemo
              ? demoAction()
              : (
                user.role.toLowerCase() === PERSONAS.RENTER
                  ? dispatch(push("/renter-portal/settings"))
                  : dispatch(push("/settings"))
              );
          }}
          data-test-id="bottom-menu-item-settings-desktop-expanded"
        >
          Account Settings
        </Menu.Item>
        <Menu.Item
          data-test-id="bottom-menu-item-logout-desktop-expanded"
          key="log-out"
          icon={detectIconHandler('logout')}
          onClick={() => {
            // AnalyticsService.reportClickEvent(EVENT_NAME.LOG_OUT, "bottom_menu", "");
            dispatch(signOut({ redirectUrl: location.pathname.substring(1), email: user.email }));
          }
          }
          className={`bottom-menus--logout ${location.pathname.includes("logout") &&
            "bottom-menus--logoutActive"
            }`}
          title={null}
        >
          Logout
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default React.memo(BottomMenus);
