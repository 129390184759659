import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { Layout } from "antd";
import PageHeader from "../../components/Header/auth-header";
import PageSidebar from "../../components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { setWindowType } from "../../store/app/action";
import { useWindowSize } from "../../hooks/useWindowSize";
import { windowSelector } from "../../store/app/selectors";
import GlobalLoading from "../../components/Shared/GlobalLoading";
import { agencyListingsTotalSelector, listingsListSelector } from "../../store/listings/selectors";
import { landlordsListSelector } from "../../store/landlords/selectors";
import { authLoadingSelector } from "../../store/user/selectors";
import { leadDataSelector, leadLoadingSelector } from "../../store/leads/selectors";
// import WelcomeModal from "./welcomeModal/WelcomeModal";
import { useLocation, useParams } from "react-router-dom";
import { getNoteComponent } from "components/NotificationsLine/helpers";
import { AnalyticsService } from "../../helpers/analyticsService";
import routes from "../../routes/routesCode";
import { clearListingsFromAlgolia } from "../../store/listings/action";
import { dashboardLoadingSelector } from "store/dashboard/selectors";
// import Message from "components/Shared/Message";
// import FloatingActionButton from "../../components/Shared/floating-action-button/FloatingActionButton";
import Cookies from "js-cookie";
import { setUserPropertiesForAnalytics } from "../../helpers/globalFunctions";
import { IObjectKeys } from "helpers/globalTypes";
import HubspotApi from "../../api/Hubspot";
import { HUBSPOT_FIELDS, SOURCE_ENUM } from "helpers/constants";
import AuthHeaderMobileOnboard from "components/Header/AuthHeaderMobileOnboard/AuthHeaderMobileOnboard";
import { EVENT_NAME } from "helpers/analytics/eventEnums";

const { Content, Footer } = Layout;
export interface RenterAuthLayoutProps {
  children: React.ReactNode;
  user?: any;
}

const RenterAuthLayout: React.FC<RenterAuthLayoutProps> = (props: RenterAuthLayoutProps) => {
  const { user } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const authLoading = useSelector(authLoadingSelector);
  const { type, collapsed } = useSelector(windowSelector);
  const dashboardLoading = useSelector(dashboardLoadingSelector);
  const landlordsList = useSelector(landlordsListSelector);
  const agencyListingsTotal = useSelector(agencyListingsTotalSelector);
  const leadsData = useSelector(leadDataSelector);
  const [fabBtnClicked, setFabBtnClicked] = useState(true);
  const [isReachedBottom, setIsReachedBottom] = useState(false);
  const [scrollingClientHeight, setScrollingClientHeight] = useState(0);
  const [bottomClientHeight, setBottomClientHeight] = useState(0);
  const [isFilterOpened, setIsFilterOpened] = useState(false);

  const onScroll = (scrollRef: any) => {
    if (isFilterOpened) {
      const epsilon = 0.5;
      const condition1 =
        Math.abs(
          scrollRef.target.scrollHeight - 170 - scrollRef.target.scrollTop + 170 - scrollRef.target.clientHeight - 170
        ) < epsilon;
      const condition2 =
        Math.floor(
          scrollRef.target.scrollHeight - 170 - scrollRef.target.scrollTop + 170 - scrollRef.target.clientHeight - 170
        ) < epsilon;
      if (condition1 || condition2) {
        setBottomClientHeight(scrollRef.target.scrollHeight - 170 - scrollRef.target.scrollTop + 170);
        setIsReachedBottom(true);
      }
    } else {
      setIsReachedBottom(false);
      setBottomClientHeight(0);
    }
  };

  const { lead_id, landlordId, lId }: any = useParams();

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [scrollEnabled, setScrollEnabled] = useState("");
  const [signUpFirstTime, setSignUpFirstTime] = useState(false);
  const [shouldReportSignUpSuccess, setShouldReportSignUpSuccess] = useState(false);
  const [isSidebarVisible, setisSidebarVisible] = useState(true);
  const [isLeadsPageMobile, setIsLeadsPageMobile] = useState(false);
  const [notification, setNotification] = useState("");
  const listingsList = useSelector(listingsListSelector);

  const { width } = useWindowSize();

  const toggleSidebar = useCallback(() => {
    const window = {
      type,
      collapsed: !collapsed,
    };
    localStorage.setItem("sidebarParams", JSON.stringify(window));
    dispatch(setWindowType(window));
  }, [type, collapsed, dispatch]);

  const routesData = useMemo(() => {
    return [
      // eslint-disable-next-line no-restricted-globals
      [routes.listing.path, location.pathname.includes(lId)],
      [routes.manageListing.path, true],
      [routes.listings.path, !(!agencyListingsTotal && !location.pathname.includes(lId)) || listingsList?.length !== 0],
      [routes.settings.path, true],
      [routes.requirementSuccess.path, true],
      [routes.home.path, true],
    ];
  }, [location, agencyListingsTotal, leadsData, landlordsList, user, lead_id, landlordId, lId, listingsList]);

  const updateHubspotContact = async (data: IObjectKeys) => {
    try {
      await HubspotApi.updateHubspotContact({
        user: data.user,
        event: data.event,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    dispatch(clearListingsFromAlgolia());
  }, [lId]);

  useEffect(() => {
    const targetRoute = routesData.find((d: any) => location.pathname.includes(d[0]));
    const leadShare = location.search.includes("isRenterShared");
    if (
      (targetRoute &&
        (targetRoute[1] || location.search) &&
        !leadShare &&
        !location.search.includes("premium_success")) ||
      location.pathname === routes.agentAllScreening.path
    ) {
      setIsHeaderVisible(true);
    } else {
      setIsHeaderVisible(false);
    }
    if (
      location.pathname.includes("requirements/success") ||
      location.pathname.includes("/reset-password") ||
      location.pathname.includes("/shareable_terms_and_conditions")
    ) {
      setisSidebarVisible(false);
      setIsHeaderVisible(false);
    } else {
      setisSidebarVisible(true);
    }
  }, [location, routesData]);

  useEffect(() => {
    if (Object.keys(user).length && !user?.phone_verified && !location.pathname.includes("settings"))
      setNotification("usersPhoneVerified");
    else setNotification("");
  }, [user, location.pathname]);

  const isFirstTime =
    !user.last_visit && !localStorage.getItem("user-last-visit") && Object.keys(user).length && user.id;

  useEffect(() => {
    if (isFirstTime) {
      setSignUpFirstTime(true);
      setShouldReportSignUpSuccess(true);
    } else {
      setSignUpFirstTime(false);
    }
  }, [isFirstTime, user]);

  useEffect(() => {
    if (shouldReportSignUpSuccess) {
      const isAllSet = setUserPropertiesForAnalytics(user.role, user.id, user.agency);
      if (isAllSet) {
        AnalyticsService.reportSignUpProcess({ action: "signup_success" });
        setShouldReportSignUpSuccess(false);
      }
      if (user.email && user.name) updateHubspotContact({ user: user, event: HUBSPOT_FIELDS.COMPLETED_SIGNUP_PROCESS });
    }
  }, [shouldReportSignUpSuccess, user.role, user.id, user.agency, user.email, user.name]);

  useEffect(() => {
    //@ts-ignore
    if ((width <= 1152 && collapsed === false) || !fabBtnClicked) {
      setScrollEnabled("disabled-scroll");
    } else setScrollEnabled("enable-scroll");
  }, [width, collapsed, fabBtnClicked]);

  useEffect(() => {
    if (window.innerHeight - bottomClientHeight < 0) {
      setScrollingClientHeight(window.innerHeight - bottomClientHeight);
    } else {
      setScrollingClientHeight(window.innerHeight);
    }
  }, [bottomClientHeight]);

  useEffect(() => {
    const unloadCallback = (event: any) => {
      localStorage.removeItem("RBsession");
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    user.adminUser ? localStorage.setItem("AdminLogin", "true") : localStorage.setItem("AdminLogin", "false");
    if (user && !localStorage.getItem("RBsession") && !user.adminUser) {
      localStorage.setItem("RBsession", "true");
      const userProperties = {
        user_id: user._id,
        agency_id: user.agency?._id,
        user_email: user.email,
        user_phone: user?.phone,
        office_name: user?.office_name,
        user_source: user.source?.name ?? SOURCE_ENUM.RENTBASE,
        phone_verified: user?.phone_verified,
        email_verified: user?.account_verified,
      };
      AnalyticsService.reportEventV2(EVENT_NAME.RB_SESSION_START, userProperties);
    }
  }, [user]);

  return (
    <div>
      {notification && !user.phone_verified && !Cookies.get("phone_message")
        ? getNoteComponent({ notification }, true)
        : ""}
      <div onScroll={onScroll} style={{ height: "100%" }}>
        <Layout
          data-test-id="page-auth-layout"
          className={`auth-layout-wrapper page-content-layout ${scrollEnabled} ${!isHeaderVisible && !location.pathname.includes("/manageListing") && "auth-layout-wrapper-onboarding"
            }`}
          hasSider
        >
          {isSidebarVisible && (
            <PageSidebar menuItems={routes} toggleSidebar={toggleSidebar} setVisibility={fabBtnClicked} />
          )}
          {type !== "desktop" && !collapsed && <div onClick={toggleSidebar} className="backdrop-layout" />}
          <Layout className="layout auth-layout-body">
            <Spin
              className={"global-loading"}
              indicator={<GlobalLoading />}
              spinning={
                (authLoading && !location.search.includes("fab")) || dashboardLoading || Object.keys(user).length < 1
              }
            >
              {isHeaderVisible ? <PageHeader setIsFilterOpened={setIsFilterOpened} /> : null}
              {!isHeaderVisible && type !== "desktop" && isSidebarVisible ? <AuthHeaderMobileOnboard /> : null}
              <Content
                className={`site-layout-background site-layout-main main-page-container ${isLeadsPageMobile ? "leads-page-layout-background" : null
                  }`}
              >
                {props.children}
              </Content>
            </Spin>
          </Layout>
        </Layout>
      </div>
    </div>
  );
};
export default RenterAuthLayout;
